import React, {Component, useState, useEffect} from 'react';
import configData from "../config.json";
import homeIcon from '../images/home.svg'
import logo from '../images/logo.png'
import { debounce } from '../utilities/helpers.js'

const Navigation = () => {
   const [prevScrollPos, setPrevScrollPos] = useState(0); 
   const [visible, setVisible] = useState(true);
 
   const handleScroll = debounce(() => {
     const currentScrollPos = window.pageYOffset;
     setVisible((prevScrollPos > currentScrollPos) || currentScrollPos < 10);
     setPrevScrollPos(currentScrollPos);
   },10);

 
   useEffect(() => {
     window.addEventListener('scroll', handleScroll);
 
     return () => window.removeEventListener('scroll', handleScroll);
 
   }, [prevScrollPos, visible, handleScroll]);

   
   function toggleNav(){
    document.querySelector('.main-nav').classList.toggle('open')
    document.querySelector('.nav-toggle').classList.toggle('open')
   }

   return(
      <nav className={visible ? "scrolled" : "scrolled"}>
      
          <div className="inner-nav">
            <div className="nav-toggle" onClick={toggleNav}>
               <span></span>
               <span></span>
               <span></span>
            </div>

            <div className="logo">
               <img src={logo} alt={configData.company} />
            </div>
          </div>
          <ul className="main-nav">
              <li>
                  <a href="#home" onClick={toggleNav}><img src={homeIcon} alt="home"/></a>
              </li>
              <li>
                  <a href="#diensten" onClick={toggleNav}>Diensten</a>
              </li>
              <li>
                  <a href="#team" onClick={toggleNav}>Ons team</a>
              </li>
              <li>
                  <a href="#contact" onClick={toggleNav}>Contacteer ons</a>
              </li>
          </ul>
          <div className="contact">
              <a href="" className="btn btn-accent">{configData.phone}</a>
              <span>24u op 24u bereikbaar</span>
          </div>
      </nav>
   )
}

export default Navigation;