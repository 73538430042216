import React from 'react';

const Divider = (props) => {

   return(

      <div className={`divider ${ props.class }`} >
         <img src={require('../images/' + props.img + '').default} alt=""/>
      </div>
   )
}

export default Divider;