import React, {} from 'react';
import Title from '../components/title'
import config from '../config.json'

import Map from '../components/map'

import location from '../images/c_location.svg'
import phone from '../images/c_phone.svg'
import email from '../images/c_email.svg'


const Contact = () => {

    return(
        <div className="container normal">
            <section id="contact">
                <Title 
                    title = "Contacteer ons"
                    subtitle = "Wij staan klaar om u te helpen."
                    class = "title-big"
                />
                <div className="grid gr-two-columns gr-center">
                    <div className="contact">
                        <div className="contact-info">
                            <div className="contact-info-item">
                                <img src={location} alt="map pin"/>
                                <span>
                                    {config.street}
                                </span>
                                <span>
                                    {config.postal + ' '}
                                    {config.city}
                                </span>
                            </div>
                        </div>

                        <div className="clearfix"></div>

                        <div className="contact-info center-text">
                            <div className="contact-info-item">
                                <img src={phone} alt="phone"/>
                                <span>{config.phone}</span>
                            </div>
                            <div className="contact-info-item">
                                <img src={email} alt="email"/>
                                <span>{config.email}</span>
                            </div>
                        </div>

                    </div>
                    <div className="map">
                       <Map />
                    </div>
                </div>
            </section>    
        </div>
    )
}

export default Contact;