import React, {} from 'react';
import Navigation from '../components/navigation';
import Helmet from 'react-helmet'

const Landing = () => {


   const seo = {
      title: "Equimedicovet - Dierenartsen",
      description:
        "Equimedicovet Dierenartsen voor inwendige ziekten en paardengeneeskunde te Herentals",
      url: "https://equimedicovet.be/",
      image: "https://getd.io/logo192.png"
    };
    
    <Helmet
      title={seo.title}
      meta={[
        {
          name: "description",
          property: "og:description",
          content: seo.description
        },
        {
         name: "viewport", content: "width=device-width, initial-scale=1"
        },
        {
         httpequiv: "Content-Type", content:"text/html; charset=utf-8"
        },
        { property: "og:title", content: seo.title },
        { property: "og:url", content: seo.url },
        { property: "og:image", content: seo.image },
        { property: "og:image:type", content: "image/png" },
      ]}
      
    />




    return (
       <>
        <section id="home">
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta http-equiv="Content-Type" content="text/html; charset=ISO-8859-1"></meta>
            <Navigation/>

        </section>
        <div class="container normal">
           <h1 class="title-big">Dierenartsenpraktijk <br/> EquiMedicoVet</h1>
           <h4>DAP EquiMedicoVet is een dynamische paardenpraktijk bestaande uit een enthousiast team van 2 dierenartsen. Wij staan garant voor kwalitatieve eerstelijns diergeneeskunde en persoonlijke service waarbij elk dier met het nodige respect wordt behandeld.</h4><p>Vroeger heette onze praktijk DAP EquiVET, maar om verwarring met een andere dierenartsenpraktijk met dezelfde naam te voorkomen, gaan wij nu verder onder de naam van EquiMedicoVet. Ons <a href="#team" class="accent">team</a> is hetzelfde gebleven.</p></div>
        </>
    )
}

export default Landing;