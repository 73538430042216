import React, {useState, useEffect} from 'react';
import { motion, useCycle } from "framer-motion"

import firebase from '../Firebase';
import Memberopen from '../pages/member-open';
import Title from '../components/title';
import locationIcon from '../images/location.svg'
import graduationIcon from '../images/graduation.svg'
import {TweenMax, Power3} from 'gsap';

function useMembers(){
    const [members, setMembers] = useState([]);

    useEffect(() => {
        firebase
            .firestore()
            .collection('members')
            .orderBy('sort')
            .onSnapshot((snapshot) => {
                const newMembers = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }))
                setMembers(newMembers);
            })
    }, [])

    return members;
}

const Memberlist = () => {
    const members = useMembers();
   
    function toggleMember($id){
      setPerson($id)
      let memberView = document.querySelector('.member-view')
      memberView.classList.add('active');
      TweenMax.from(
         memberView.querySelector('.view'),
         .8,
         {
            opacity: 0,
            y: "-50%",
            delay: .3,
         }
      )
    }

    const [person, setPerson] = useState();
    
    // const [active, setActive] = React.useState(false);
    // const [animate, cycleCard] = useCycle({scale: 1.0}, {scale: 1.2});

    return (
        <section id="team">
            <div className="container normal">
                <Title 
                  title="Ons team"
                  subtitle="Wij staan voor u klaar"
                  class="title-big"
                />
                <h4>
                  Vroeger heette onze praktijk EquiVET, maar om verwarring met een andere dierenartsenpraktijk met dezelfde naam te voorkomen, gaan wij nu verder onder de naam van EquiMedicoVet. Ons team is hetzelfde gebleven.
                </h4>
                <div className="grid gap-medium gr-three-columns">
                    {members.map((member) =>
                        <motion.div 
                            key={member.id} 
                            onClick={() => setPerson(member.id)}
                            // onClick={() => setActive(!active)} 
                            // onClick={() => cycleCard(member.id)}
                            // animate={animate}
                            className="member"
                        >
                            {
                                member.image && 
                                <div className="image-container">
                                    <motion.img 
                                        src={require(`../images/${member.image}`).default}
                                        whileHover={{
                                          //   scale: 1.1,
                                          //   transition: {duration: .5}
                                        }}                                    
                                    />
                                    <div className="image-container-overlay">
                                       <button 
                                          className="btn"
                                          key={member.id} 
                                          onClick={() => toggleMember(member.id)}
                                       >
                                          Meer info
                                       </button>
                                    </div>
                                </div>
                            }
                            <div className="member-basic-info">
                              <div className="member-title">
                                 <span>{member.function}</span>
                                 <h2>{member.function} {member.name}</h2>
                              </div>
                                 {member.graduation ? 
                                    <ul>
                                       <li>
                                          <img src={graduationIcon} alt="graduation icon"/>
                                          {member.graduation}
                                       </li>
                                       <li>
                                          <img src={locationIcon} alt="location icon"/>
                                          {member.study_location}
                                       </li>
                                    </ul>
                                    :
                                    null
                                 }

                            </div>
                        </motion.div>
                    )}           
                </div>
                    
            </div>
            <div className="member-view">
               <Memberopen name={person}/>
            </div>
            
        </section>
    )
}

export default Memberlist;