import React, {} from 'react';


const Footer = () => {

   return(
      <footer>
         <div className="container normal">
            <p>Copyright &#169; {new Date().getFullYear()} <a href="https://cmctelecom.be/ict/service/webdesign">CMCweb</a></p>
         </div>
      </footer>
   )
}

export default Footer;