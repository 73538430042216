import React, {useState, useEffect, useRef} from 'react';
import firebase from '../Firebase';
import {TweenMax, Power3} from 'gsap';
import Title from '../components/title';
import Divider from '../components/divider';

function useService(){
    const [services, setServices] = useState([]);

    useEffect(()=>{
        firebase
            .firestore()
            .collection('services')
            .orderBy('order')
            .onSnapshot((snapshot) => {
                const newServices = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }))
                setServices(newServices);
            })
    }, [])
    return services;
}

function renderService(service, index){

    function generateInfo(service){
        return(
            <>
                <Title 
                    title={service.name}
                    class="title-deco"
                    deco="true"
                />
                <ul>
                    {service.info && service.info.map((info) => {
                        return(<li key={info}>{info}</li>)
                    })}
                </ul>
                
            </>
        )
    }

    return(
         <div>
            <div className="service-image">
               {service.img && <img src={require(`../images/${service.img}`).default}/>}
            </div>
            {generateInfo(service)}

         </div>
    )

}

const Services = () => {
    const services = useService();
    let serviceElement = useRef(null);

    useEffect(() =>{
        TweenMax.from(
            serviceElement,
            .8,
            {
                opacity: 0,
                y: -100,
                stagger: .8,
                ease: Power3.easeInOut
            }
        )
    }, [])
    return(
        <section id="diensten">
            <div className="container normal">
            <Title 
                reference={el => {serviceElement = el}} 
                title="Onze diensten" 
                subtitle="Wat wij doen"
                class="title-big"
            />
               
               <div className="grid gr-two-columns gr-center gap-big">
                  {services.map((service, index) =>
                     <div 
                           key={service.id} 
                           className='service' 
                           id={service.name} 
                           className=""
                     >
                           {renderService(service, index)}
                     </div>
                  )}
               </div>
            </div>
            <Divider 
               class = "full-width spacing-big"
               img = "canstockphoto4549303.c3820a02.jpg"
            />
        </section>
    )
}

export default Services;