const Title = (props) => {
    return ( 
        <div className="title">
            {props.subtitle &&
                <span>{props.subtitle}</span>
            }
            <h2 ref={props.reference && props.reference} className={props.class}>{props.title}</h2>
            {props.deco &&
             <div className="deco-line">
                <span className="big"></span>
                <span className="small"></span>
            </div>
            }
           
        </div>
     );
}
 
export default Title;