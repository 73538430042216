import React, { Component } from 'react';

import Landing from './pages/landing';
import Services from './pages/services';
import Memberlist from './pages/member-list';
import Memberopen from './pages/member-open';
import Contact from './pages/contact';
import Footer from './pages/footer';

function App() {
    return (
      <div className="App">
        <Landing/>
        <Services/>
        <Memberlist/>
        <Memberopen/>
        <Contact/>
        <Footer/>
      </div>
    )
}


export default App;
