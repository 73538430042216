import React, {useState, useEffect, useRef} from 'react';
import firebase from '../Firebase';
import {TweenMax, Power3} from 'gsap';

function useMember(id){
    const [memberc, setMember] = useState([]);
    
    useEffect(() => {
        firebase
            .firestore()
            .collection('members')
            .doc(id)
            .get()
            .then(doc => {
                const memberc = doc.data();
                setMember(memberc);
            });
    }, [id])
    return memberc;
}
const Memberopen = (props) =>{
    const member = useMember(props.name);
    const memberView = document.querySelector('.member-view')
    function close(){
       memberView.classList.remove('active');
    }
    let memberName = useRef();

    useEffect(() =>{
        TweenMax.from(
            memberName.current,
            .8,
            {
                opacity: 0,
                y: -100,
                stagger: .8,
                ease: Power3.easeInOut
            }
        )
    }, [])
    return(
        <div className="view">
            {member && 
                <div className="">
                    <h2>{member.name}</h2>
                    <p>{member.function}</p>
                    <p>{member.about}</p>
                     <div className="close" onClick={() => close()}>X</div>
                </div>
            }
        </div>
        
    )
}

export default Memberopen;