import firebase from 'firebase';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyD5nzCr7FXUFQGMRJXfl9hG3UDDNqjDxLs",
    authDomain: "ingkam-c6e2a.firebaseapp.com",
    databaseURL: "https://ingkam-c6e2a.firebaseio.com",
    projectId: "ingkam-c6e2a",
    storageBucket: "ingkam-c6e2a.appspot.com",
    messagingSenderId: "760647393479",
    appId: "1:760647393479:web:1c902fec8677c31bb69ee2",
    measurementId: "G-Y59S7P8L07"
}

firebase.initializeApp(firebaseConfig);

export default firebase;