


const Map = () =>{

   return(
      <iframe 
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2497.9505909480044!2d4.953406915932697!3d51.238404838156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c14cca08ff396d%3A0x5cebe9de90897717!2sLichtaartsebaan%208%2C%202460%20Kasterlee!5e0!3m2!1sen!2sbe!4v1607613531488!5m2!1sen!2sbe" 
      width="100%" 
      height="340" 
      frameBorder="0" 
      style={{ border: 0 }}
      allowFullScreen="" 
      aria-hidden="false" 
      tabIndex="0"
   >
   </iframe>
   )

}

export default Map

